@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light dark;
  /* Colores del modo claro */
  --background-light: #FFFFFF;
  --text-light: #151C24; /* Color para el modo claro */
  --primary-light: #ED4251; /* Color primario para el modo claro */
  --secondary-light: #556379; /* Color secundario para el modo claro */
}

.dark {
  /* Colores del modo oscuro */
  --background-dark: #151C24;
  --text-dark: #FFFFFF; /* Color para el modo oscuro */
  --primary-dark: #ED4251; /* Color primario para el modo oscuro */
  --secondary-dark: #556379; /* Color secundario para el modo oscuro */
}

/* Aplicación global */
body {
  @apply antialiased;
  background-color: var(--background-light);
  color: var(--text-light);
}

/* Cuando está en modo oscuro */
.dark body {
  @apply bg-background-dark text-text-dark;
  background-color: var(--background-dark);
  color: var(--text-dark);
}
